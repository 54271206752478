// $primary: #a51616;
$primary: #093542; /* MAIN COLOR */
$secondary: #7894a3; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;


h1, h2, h3 {font-family: 'Bevan', cursive;}

a, p {font-family: 'Reem Kufi', sans-serif;}

.row {margin-right: 0px;
	  margin-left: 0px;}


.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 35px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: transparent;
	    	color: lighten($primary, 50%);
	    }
	}
}

.navbar-right {
	margin-top: 25px;
	font-size: 2em;

	@media (max-width: 1024px) {
		margin-top: 5px;
		font-size: 1.5em;
		margin-top: 0px;
		margin-right: 0px;
	  margin-left: 0px;
	  margin-bottom: 0px;
	  padding: 0px;
	}

}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 767px) {
		margin-top: 20px;
	}
}

.sticky-wrapper {height: 108px !important;
					@media (max-width: 767px) {
						height: 80px !important;
					}
				}




/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}



/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color: transparent;
    border-color: $wht;
	   color: $wht;
padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 12px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
	 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.btn-download:hover {
    background-color: $primary;
    color: $wht;

}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
	margin-top: 50px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}

/* ========== HEADER ============== */

.header {background-image: url('../img/banner.jpg');
		 background-repeat: no-repeat;
		 background-position: 50% 10%;
		 background-size: cover;
 		 -moz-background-size: cover;
		 -o-background-size: cover;
  		 -webkit-background-size: cover;
		   
	@media (max-width: 1199px) {
		background-position: 15% 20%;
	}

}

.header-overlay {
  background-color: rgba(31, 31, 31, 0.5);
  padding: 200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


    @media (max-width: 1024px) {
			padding: 5px;

	}

	@media (max-width: 1199px) {
			padding: 25px;

	}

	@media (min-width: 1200px) and (max-width: 1439px) {
		padding: 25px;
	}


}

.header h1 {color: $wht;
			font-size: 5em;
			text-align: center;
			
			@media (max-width: 1024px) {

				font-size: 3em;
			}
}


.logo {
  width: 100%;
  max-width: 300px;
  margin-left: 10px;
  padding: 5px;


  @media (max-width: 1024px) {
	   	   width: 100%;
			max-width: 217px;
			margin-left: 5px;
			padding: 5px;
  }  

}

  .header img {display: block;
			 margin: 0 auto;
			 width: 100%;
			 max-width: 600px;}
			 
/* ========== MINIFORM AREA ============== */

.miniform {padding: 50px;
		   background-image: url('../img/miniform-bg.png');
		   background-repeat: no-repeat;
		   background-position: 60% 60%;
		   background-size: cover;
		   -moz-background-size: cover;
		   -o-background-size: cover;
		   -webkit-background-size: cover;

		@media (max-width: 1024px) {
			padding: 35px 0px;
			text-align: center;
		}


}

.miniform h1 {font-size: 4em;
			  color: $primary;
			  text-shadow: 2px 2px $secondary;
			  padding: 10px;
			}

.miniform p {font-size: 1.65em;
			 letter-spacing: 1px;
			 line-height: 40px;
			 padding: 10px;}

/* ========== BENEFITS ============== */


.benefits {background-image: url('../img/benefits-bg.jpg');
		   background-repeat: repeat;
		   text-align: center;

}

.benefits-overlay {
  background-color: rgba(31, 31, 31, 0.75);
  padding: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  @media (max-width: 1024px) {
	  padding: 0px;
	  text-align: center;
  }



}

.benefits h1 {font-size: 3.5em;
		      color: $wht;
			  text-shadow: 2px 2px $primary;


			  @media (max-width: 1024px) {
				  font-size: 3em;
			  }
	}

.benefits p {font-size: 1.65em;
			 letter-spacing: 1px;
			 line-height: 40px;
			 padding: 10px;
			 color: $wht;}

.benefits img {width: 100%;
			   max-width: 550px;
			   display: block;
			   margin: 0 auto;
			   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			
			@media (max-width: 767px) {
				  padding-top: 0px
			  }
			
			
			@media (max-width: 1024px) {
				  margin-top: 25px;
			  }

			@media (max-width: 1199px) {
				  margin-top: 60px
			  }
}


/* ========== FEATURES ============== */

.features {padding: 50px;

			@media (max-width: 1024px) {
				padding: 0px;
			}


}

.features h1 {font-size: 4em;
			  color: $primary;
			  text-shadow: 2px 2px $secondary;
			  text-align: center;

			  @media (max-width: 1024px) {
				  font-size: 3em;
			  }
			  
			  
			  }

.features h2 {font-size: 2em;
			  color: $primary;
			  text-shadow: 2px 2px $secondary;
			  
			  @media (max-width: 1024px) {
				  text-align: center;
			  }
			  
			  
			  }

.features p {font-size: 1.65em;
			 letter-spacing: 1px;
			 line-height: 30px;
			 color: $primary;
			 
			@media (max-width: 1024px) {
				  text-align: center;
			  }
			 
			 
			 }

.features img {width: 100%;
			   max-width: 550px;
			   display: block;
			   margin: 0 auto;


			   @media (max-width: 1024px) {
				   max-width: 250px;
			   }
		}